import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthServiceService } from "../biz/auth-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { SpinnerVisibilityService } from "ng-http-loader";
@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.css"]
})
export class LoginPageComponent implements OnInit {
  test: Date = new Date();
  focus;
  focus1;
  loginForm;
  loading = false;
  submitted = false;
  returnUrl: string;
  error: string = null;
  resp: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private service: AuthServiceService,
    private spinner: SpinnerVisibilityService
  ) {
    
    this.loginForm = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.service.logout();
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] || "/home/base";
  }

  onSubmit(loginData) {
    this.submitted = true;
    this.error = null;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.spinner.show();
    // Process checkout data here
    this.service
      .authenticate(loginData.email, loginData.password)
      .subscribe(data => {
        this.spinner.hide();        
        if(!this.returnUrl){
          this.router.navigate(["/home/base"]);        
        }else{
          var redirectFull=this.returnUrl.split("?");
          var url = redirectFull[0];
          if(redirectFull.length>1){
            var qp =redirectFull[1].split("&");
            var qpjson = {}
            for(var i=0;i<qp.length;i++){
              let jsonkey= qp[i].split("=")[0];
              let value = qp[i].split("=")[1];
              if(!qpjson[jsonkey]){
                qpjson[jsonkey]=value
              }else{
                if (typeof qpjson[jsonkey] === 'string') {
                  let v = qpjson[jsonkey];
                  qpjson[jsonkey] = [v];

                }
                qpjson[jsonkey].push(value);
                
              }
            }
            this.router.navigate([url],{queryParams:qpjson});        
          }else{
            this.router.navigate([this.returnUrl]);        
          }
        }
      }, (err) => {
        this.spinner.hide();
        console.log(err)
        if (err.status != null) {
          switch (err.status) {
            case 0:
              this.error = 'Não foi possível contactar o servidor, por favor verifique sua conexão.'
              return;
            case 401:
              this.error = 'Usuário não encontrado ou senha inválida.'
              return;
          }
        }
        this.error = 'Houve um erro inesperado, por favor tente novamente.'

      }
      );

    this.loginForm.reset();
  }
}
